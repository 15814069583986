h1 {
    font-family: Arial, Helvetica, sans-serif;
  }
  
  input {
    width: 300px;
    height: 40px;
    font-size: 16px;
    margin: 20px auto;
    display: block;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .fetch-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    font-family: Arial, Helvetica, sans-serif;
  }
  

