.registration-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-group {
    margin: 10px 0;
  }
  
  label {
    font-size: 1rem;
    font-weight: bold;
    margin-right: 10px;
  }
  
  input[type="text"] {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  input {
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #ccc;
    margin-bottom: 1rem;
    width: 100%;
    max-width: 20rem;
  }
  
  button[type="submit"] {
    background-color: #0077ff;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #0059b3;
  }
  
  /* Styles for the table */
  table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 2rem;
  }
  
  thead {
    background-color: #0077ff;
    color: #fff;
  }
  
  th, td {
    padding: 0.5rem;
    text-align: left;
    border: 1px solid #ccc;
  }
  
  th {
    font-weight: bold;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  @media screen and (max-width: 600px) {
    /* Responsive styles for the form */
    input {
      max-width: 100%;
    }
  
    /* Responsive styles for the table */
    table {
      font-size: 0.8rem;
    }
  
    th, td {
      padding: 0.2rem;
    }
  }